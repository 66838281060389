import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loaderCount = 0;
  loaderChangeCountSubject$ = new Subject<number>();
  loaderChangeCount$ = this.loaderChangeCountSubject$.pipe(map(val => this.loaderCount));

  constructor() { }
  startLoader() {
    this.loaderCount++;
    this.loaderChangeCountSubject$.next(1);
  }
  stopLoader() {
    if (this.loaderCount > 0) {
      this.loaderCount--;
      this.loaderChangeCountSubject$.next(-1);
    }
  }
}
