<header class="pb-4">
  <div class="header-thinline"></div>
  <div class="header-topline">
    <div class="header-curve"></div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <a href="http://www.worksafebc.com"
          ><img
            class="img-fluid d-block"
            src="https://ux-static.online.worksafebc.com/css/assets/img/worksafebc-logo.jpg"
        /></a>
      </div>
      <div
        class="col-6 d-flex justify-content-end align-items-center text-right d-print-none"
      >
        <ng-container *ngIf="{ value: onExitUrl$ | async } as onExitUrl">
          <a
            class="exitbutton"
            id="applicaion-exit"
            (click)="onExitClick(onExitUrl.value)"
            href="javascript:void(0)"
            >Exit</a
          >
          <app-action-confirm-modal
            *ngIf="showExitModal"
            (confirmed)="showExitModal = false; exitConfirmed(onExitUrl.value)"
            (canceled)="showExitModal = false"
            modalTitle="Are you sure you want to exit?"
            confirmBtnTxt="Exit"
            cancelBtnTxt="Cancel"
          >
            If you exit now, your document<span>{{
              onExitDocumentsWithFileAttachedCount > 1 ? "s" : ""
            }}</span>
            will not be submitted to us or added to the claim.
          </app-action-confirm-modal>
        </ng-container>
      </div>
    </div>
  </div>
</header>
