import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


/***
 * This service is used to emit a boolean representing if upload is progressing or not.
 */
@Injectable({
  providedIn: 'root'
})
export class UploadProgressService {
  uploadProgressing$ = new BehaviorSubject<boolean>(false);
  constructor() { }
}
