<!--Action confirmation Modal box -->

<div
  bsModal
  [config]="{ show: true }"
  (onHidden)="modalHidden(actionConfirmed)"
  #smModal="bs-modal"
  class="modal fade small-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name2"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="smallModalTitle">{{ modalTitle }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="smModal.hide()"
        >
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer flex-md-row flex-column-reverse">
        <a
          class="btn btn-md btn-outline-blue d-block d-md-inline-block"
          data-dismiss="modal"
          href="javascript:void(0)"
          (click)="smModal.hide()"
          >{{ cancelBtnTxt }}</a
        >
        <a
          class="btn btn-md btn-blue d-block d-md-inline-block"
          href="javascript:void(0)"
          (click)="confirmClicked()"
          >{{ confirmBtnTxt }}</a
        >
      </div>
    </div>
  </div>
</div>
