<div class="direct-contact w-100">
  <div class="container">
    <hr />
    <p>
      <b>Have questions about your documents or claim? Call us at</b>&nbsp;<a
        href="tel:+18889675377"
        >1.888.967.5377</a
      >.
    </p>
    <p>
      <b>Need technical support? Call our Support Desk at</b>&nbsp;<a
        href="tel:+18888552477"
        >1.888.855.2477</a
      >.
    </p>
  </div>
</div>
