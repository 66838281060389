import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { of, Observable } from 'rxjs';
import { filter, map, take, tap, catchError, share } from 'rxjs/operators';
/***
 * This service handles the behavior of the application when user clicks on the exit button
 * The exit Url can be captured from the hyperlink to the application in one of the following 3 different methods:
 * 1- setting the referrerpolicy="unsafe-url" in the hyperlink (example: <a href="https://clmdocumentupload.online.worksafebc.com/" target="_blank" referrerpolicy="unsafe-url">Upload form</a>
 * 2- passing the exitUrl as a base64 url encoded token string query parameter as "onexittoken" in the hyperlink, (example: <a href="https://clmdocumentupload.online.worksafebc.com?onExitUrl=aHR0cHM6Ly93d3cud29ya3NhZmViYy5jb20%3D")
 * 3- passing the exitUrl as url encoded string query parameter as "onexiturl" in the hyperlink, (example: <a href="https://clmdocumentupload.online.worksafebc.com?onexiturl=https%3A%2F%2Fwww.worksafebc.com")
*/


const DEFAULT_ONEXIT_URL = "https://www.worksafebc.com";

@Injectable({
  providedIn: 'root'
})
export class OnExitService {

  constructor(private location: Location, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  public getOnExitUrl$: Observable<string> =
    // first we check if there is already a saved value for exitUrl in session storage: (this will be useful in case user refreshes the page)
    this.onExitUrl !== null ?
      of(this.onExitUrl) :
      this.router.events
        .pipe(
          filter((event: Event) => !!(event instanceof NavigationEnd)),
          take(1),
          map(() => {
            let onExitUrl: string;
            const onExitTokenFromQuery =
              this.activatedRoute.snapshot.queryParamMap.get('onexittoken');
            const onExitUrlFromQuery =
              this.activatedRoute.snapshot.queryParamMap.get('onexiturl');
            const referrer = document.referrer;

            if (onExitTokenFromQuery) {
              onExitUrl = this.urlDecodeFromToken(onExitTokenFromQuery);
              // updating url with no query params (without reload)
              this.location.go(window.location.pathname + window.location.search.replace(/(&onexittoken=([^&]*)|onexittoken=([^&]*)&?)/, '').replace(/^\?$/, ''));
            } else if (onExitUrlFromQuery !== null) {
              onExitUrl = decodeURIComponent(onExitUrlFromQuery);
              // updating url with no query params (without reload)
              this.location.go(window.location.pathname + window.location.search.replace(/(&onexiturl=([^&]*)|onexiturl=([^&]*)&?)/, '').replace(/^\?$/, ''));
            }
            else if (referrer && referrer.indexOf(document.location.origin) !== 0) {
              onExitUrl = referrer;
            }
            else {
              onExitUrl = DEFAULT_ONEXIT_URL;
            }
            this.onExitUrl = onExitUrl;
            return onExitUrl;
          }
          ),
          catchError(err => {
            this.onExitUrl = DEFAULT_ONEXIT_URL;
            this.location.go(window.location.pathname);
            return of(DEFAULT_ONEXIT_URL)
          }),
        )


  get onExitUrl(): string {
    return sessionStorage.getItem('onExitUrl');
  }
  set onExitUrl(url: string) {
    sessionStorage.setItem('onExitUrl', url);
  }

  // helper methods to encode/decode url tokens:
  urlEncodeToToken(url: string): string {
    return encodeURIComponent(btoa(url))
  }
  urlDecodeFromToken(token: string): string {
    return atob(decodeURIComponent(token));
  }

}
