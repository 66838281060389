import { Component, Input, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { tap, debounceTime, map, filter } from 'rxjs/operators';
import { LoaderService } from './loader.service';

/**
 * This component is resposible to show the loading overlay based on one any of these conditions:
 *  1- Subscribing to loaderService.loaderChangeCount$, this is the generic way and will show the loading component at the root level
 *     when any http call is in progress
 *  2- Subscribing to show$ observable which is passed as an input to the component, this is an optional parameter which allows other
 *     components to activate the loading overlay directly (usually this method is prrefered while loading grid contents).
 *
 */

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loading$: Observable<boolean>;
  private _show = false;
  @Input() show$: Observable<boolean>;
  @Input() showOverlay = false;
  @Input() compact = false;
  @Input() message = "Loading";
  constructor(private loaderService: LoaderService) { };
  ngOnInit(): void {
    this.setupLoading();
  }

  setupLoading() {
    this.loading$ =
      merge(
        (this.show$ || new Observable())
          .pipe(
            tap(show => this._show = show),
            filter((show) => this.loaderService.loaderCount === 0 || !show)
          ),
        this.loaderService.loaderChangeCount$
          .pipe(
            debounceTime(500),
            map(loadCount => (loadCount > 0 && !this.show$) || (loadCount === 0 && this._show)))
      )
  }

}
