import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '@environment/environment';


export interface OperatinalFlags {
  qa: boolean;
  mock: boolean;
}

const VALID_ENVIRONMENTS = ['local', 'dv', 'sy', 'mock'];


@Injectable({
  providedIn: 'root'
})
export class AppOperationModeService {
  operationFlags: OperatinalFlags = { qa: false, mock: false };

  public operatinalFlags$: BehaviorSubject<OperatinalFlags> = new BehaviorSubject(this.operationFlags);
  constructor(private router: ActivatedRoute) {
    this.init(environment.name);
  }

  private init(environmentName: string) {
    // only operate on valid environments
    if (VALID_ENVIRONMENTS.indexOf(environmentName) === -1) { return };
    this.getOperationFlagFromStorage();
    this.setOperationFlagFromQueryParams(this.router).subscribe();
  }

  getOperationFlagFromStorage() {
    // settign all flags to false first;
    Object.keys(this.operationFlags).forEach(flag => this.operationFlags[flag] = false);
    // update flags from session storage:
    sessionStorage.getItem('operationflags')?.split(',').map(item => item.trim())
      .forEach(flag => { if (this.operationFlags[flag] !== undefined) { this.operationFlags[flag] = true } });
    this.operatinalFlags$.next(this.operationFlags);
    return this.operationFlags;
  }

  setOperationFlagFromQueryParams(router: ActivatedRoute) {
    return router.queryParams
      .pipe(
        filter((params: any) => params.operationflags),
        map((params: any) => params.operationflags.toLowerCase()),
        map(operationFlagsStr => operationFlagsStr.split(',')),
        // trim all entries
        map(operationFlagsArr => operationFlagsArr.map(item => item.trim())),
        // only keep valid flags which are defined in the operationFlags object:
        map(operationFlagsArr => operationFlagsArr.filter(flag => this.operationFlags[flag] !== undefined)),
        tap((operationFlagsArr) => this.storeOperationFlags(operationFlagsArr)),
        // refresh the page, the operationFlags will get updated from session storage on next reload
        tap(() => window.location.href = window.location.origin + window.location.pathname),
      );
  }

  storeOperationFlags(operationFlagsArr: string[]) {
    const operationFlagsStr = operationFlagsArr.join(',');
    if (operationFlagsStr) {
      sessionStorage.setItem('operationflags', operationFlagsStr);
    } else {
      sessionStorage.removeItem('operationflags');
    }
  }

  resetAllModes() {
    this.storeOperationFlags([]);
    // refresh the page:
    window.location.href = window.location.origin + window.location.pathname;
  }
}
