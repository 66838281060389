import { UserInfoService } from './../../multi-file-uploader/components/user-info/user-info.service';
import { DocumentEditState } from './../../multi-file-uploader/services/wsbcDocuments/wsbc-documents.service';
import { Observable } from 'rxjs';
import { OnExitService } from './../services/on-exit.service';
import { Component, OnInit } from '@angular/core';
import { WsbcDocumentsService } from '@app/modules/multi-file-uploader/services/wsbcDocuments/wsbc-documents.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  onExitUrl: string;
  onExitUrl$: Observable<string>;
  showExitModal = false;
  onExitDocumentsWithFileAttachedCount: number;

  constructor(onExitService: OnExitService, public wsbcDocumentsService: WsbcDocumentsService, private userInfoService: UserInfoService) {
    this.onExitUrl$ = onExitService.getOnExitUrl$;
  }
  ngOnInit() {
  }
  exitConfirmed(onExitUrl) {
    if (onExitUrl === '') {
      window.close()
    }
    else {
      window.location.replace(onExitUrl);
    }


  }
  onExitClick(onExitUrl: string) {
    // based on business rules the exitModal should only show if:
    // 1- exit has not been triggered before upload process has been completed ( in this case userInfoService.userInfo.documentUploadDate has not been set yet)
    // 2- exit has been triggered with at least one document with file attachment (either added to documents or just attached in edit mode)

    this.onExitDocumentsWithFileAttachedCount = this.wsbcDocumentsService.documents.filter(document => (document.files && document.files.length) || document.editState === DocumentEditState.editFileAttached).length;
    const shouldShowExitModal = !this.userInfoService.userInfo.documentUploadDate &&
      (this.wsbcDocumentsService.hasDocumentsWithFiles() ||
        this.onExitDocumentsWithFileAttachedCount > 0)
    if (shouldShowExitModal) {
      this.showExitModal = true;
    }
    else { this.exitConfirmed(onExitUrl) }

  }

}
