<footer
  class="d-print-none"
  [ngClass]="{
    compact: (uploadProgressService.uploadProgressing$ | async) === true
  }"
>
  <app-direct-contact></app-direct-contact>
  <div class="footer-curve">
    <div class="footer-curve-bg"></div>
  </div>
  <div class="footer-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <span
            ><a
              target="_blank"
              class="contactus"
              href="https://www.worksafebc.com/en/contact-us"
              >Contact us</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom p-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-right">
          <span
            ><a
              target="_blank"
              href="https://www.worksafebc.com/en/legal/copyright-and-trademark/"
              >Copyright 2021</a
            >
            |
            <a
              target="_blank"
              class=""
              href="https://www.worksafebc.com/en/legal/terms-of-use"
              >Terms of use</a
            >
            |
            <a
              target="_blank"
              href="https://www.worksafebc.com/en/about-us/fairness-privacy/protection-of-privacy"
              >Privacy</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</footer>
