import { ActionConfirmModalComponent } from './components/action-confirm-modal/action-confirm-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [ActionConfirmModalComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),

  ],
  exports: [ActionConfirmModalComponent]
})
export class SharedModule { }
