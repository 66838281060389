<div *ngIf="loading$ | async">
  <div [class.overlay]="showOverlay">
    <div class="border-0" [ngClass]="{'container content':!compact}">
      <!--taken from https://ux-static.online.dv.worksafebc.com/#loading -->
      <div class="row">
        <div [ngClass]="{'py-5 text-center col-12':!compact}">
          <div class="sk-three-bounce" [class.m-0]="compact">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
            <div class="sk-child sk-bounce4"></div>
          </div>
          <span><strong>{{message}}</strong></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12"> </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row"> </div>
        </div>
      </div>
    </div>
  </div>
</div>
