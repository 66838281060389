import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-action-confirm-modal',
  templateUrl: './action-confirm-modal.component.html',
  styleUrls: ['./action-confirm-modal.component.scss']
})
export class ActionConfirmModalComponent implements OnInit {
  @ViewChild('smModal', { static: false }) smModal?: ModalDirective;

  @Output() confirmed = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Input() confirmBtnTxt: string;
  @Input() cancelBtnTxt: string;
  @Input() modalTitle: string;

  // this variable is to keep the state of modal when it is getting closed:
  public actionConfirmed = false;

  constructor() { }

  ngOnInit(): void {
  }

  confirmClicked() {
    // this will cause the modal to close, however the actual emitting the of the confirmation event is done after the modal is hidden
    // this ensures the animation of modal getting disappeared is complete before emitting the event.
    try {
      this.actionConfirmed = true;
      this.smModal.hide();
    } catch (err) {

    }
  }

  // this function is called from the hidden event of the modal
  modalHidden(actionConfirmed: boolean) {
    if (actionConfirmed) {
      this.confirmed.emit();
    } else {
      this.canceled.emit();
    }
  }

}
