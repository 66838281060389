import { Injectable } from '@angular/core';

export interface UserInfo {
  claimNumber: string;
  dateOfBirth: Date;
  lastName: string;
  email?: string;
  referenceNumber?: string;
  documentUploadDate?: Date;
  captcha?: string;
  // timestamp for when the captcha token expires on backend , Note this is different than the expiry of the token lifecycle itself
  // this token will get set in the verifyUser function from api.service according to the tokenDuration (plus a buffer time) in backend response
  tokenExpiry?: number;
}
@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  verificationComplete: boolean;
  private _userInfo: UserInfo;
  get userInfo() { return this._userInfo };
  set userInfo(userInfo: UserInfo) {
    this._userInfo = { ...userInfo }
  };

  reset() {
    this._userInfo = { lastName: '', dateOfBirth: null, claimNumber: null, email: null };
    this.verificationComplete = false;
  }
  constructor() {
    this.reset();
  }
}
