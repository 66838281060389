export const environment = {
  production: true,
  name: 'dv',
  documentUploaderApiBaseUrl: '/api/v1',
  opLoginLink: 'https://op.online.sy.worksafebc.com/op',
  claimViewLink: 'https://clmwvcclaims.online.sy.worksafebc.com/Documents',
  reCaptcha: {
    siteKey: '6LeQbYYlAAAAAMQHD5kIyWHEJujQE32FHka11gBw'
  },
  appInsights: {
    instrumentationKey: 'bd6ebdab-a31d-4f09-b1c8-7fa557350457'
  },
  surveyLink: 'https://surveytest.ca.matchbox.maruhub.com/index/index/s/ESV-dy02-120156633?&vault=dy02'
};
