import { SharedModule } from './../shared/shared.module';
import { MultiFileUploaderModule } from './../multi-file-uploader/multi-file-uploader.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@app/modules/header-footer/footer/footer.component';
import { HeaderComponent } from '@app/modules/header-footer/header/header.component';
import { DirectContactComponent } from '@app/modules/multi-file-uploader/components/direct-contact/direct-contact.component';
import { ActionConfirmModalComponent } from '../shared/components/action-confirm-modal/action-confirm-modal.component';




@NgModule({
  declarations: [FooterComponent, HeaderComponent, DirectContactComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    HeaderComponent, FooterComponent, DirectContactComponent
  ]
})
export class HeaderFooterModule { }
