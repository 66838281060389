import { WsbcDocumentMetadata, WsbcProviderMetadata } from './../api.service';
import { Injectable } from '@angular/core';


export interface WorkerDocument {
  editState: DocumentEditState;
  wsbcDocumentMetadata: WsbcDocumentMetadata;
  wsbcProviderMetadata?: WsbcProviderMetadata;
  files: File[];
  title: string;
}

export enum DocumentEditState {
  preview = 0, // not in edit mode
  editNoSelection = 1,  // user has not yet interacted in edit mode 
  editFileAttached = 2, // user has attached a file in form control but the file is not yet attached to the wsbcDocumentService.documents;
}

@Injectable({
  providedIn: 'root'
})
export class WsbcDocumentsService {
  public activeDocumentIndex = 0;
  private _documents: WorkerDocument[];
  get documents() {
    return this._documents;
  }

  constructor() {
    this.setFirstDocument();
  }
  setFirstDocument() {
    this._documents = [
      {
        editState: DocumentEditState.editNoSelection,
        wsbcDocumentMetadata: null,
        wsbcProviderMetadata: null,
        files: null,
        title: 'Document 1'
      }
    ]
  }

  addNewDocumentSection(documents: WorkerDocument[]) {
    // set any other section editState to preview:
    documents.forEach(document => document.editState = DocumentEditState.preview);

    // add a new document:
    documents.push(
      {
        editState: DocumentEditState.editNoSelection,
        wsbcDocumentMetadata: null,
        files: null,
        title: `Document ${documents.length + 1}`
      }
    );
  }

  setDocumentEditMode(documentIndex) {
    this.documents[documentIndex].editState = DocumentEditState.editNoSelection;
  }

  setAllPreviewMode() {
    this.activeDocumentIndex = undefined;
    this.documents.forEach((document, index) => document.editState = DocumentEditState.preview);
  }

  removeAllDocumentsWithNoFilesAttached() {
    // only keep the first one
    this._documents = this._documents.filter((doc, index) => index === 0 || doc.files && doc.files.length);
  }



  documentRemoved(ind: number) {
    this._documents.splice(ind, 1);
    // if last document was removed populate the documents with the default document (Marketing requirement)
    if (this.documents.length === 0) {
      this.setFirstDocument();
    }
    return this._documents.length;
  }

  hasDocumentsWithFiles() {
    return this._documents && this._documents.length && this._documents.some(document => document.files && document.files.length)
  }
  allDocumentsWithFiles() {
    return this._documents.filter(document => document.files && document.files.length)
  }
}
